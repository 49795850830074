import { FetchContext } from "hooks/FetchContext";
import React, { useState, useEffect, useContext, useCallback } from "react";
import fetchUserData from "utils/get/FetchUserData";



const StupuPoints = ( ) => {
    const { authAxios } = useContext(FetchContext);
    const [userData, setUserData] = useState();

    const getData = useCallback(async () => {
        try {
          const data = await fetchUserData(authAxios);
          setUserData(data);
        } catch (error) {
          console.error("Error fetching user data"); // <== Debugging
        }
      }, [authAxios]);
      
      
      useEffect(() => {
        getData();
      }, [getData]);


  return (
    <div className="stats-item">
      <div className="stats-amount">{userData?.stuPuPoints}</div>
      <p>StuPu Points</p>
    </div>
  );
};

export default StupuPoints;
