import React, { useState } from "react";
import Button from "components/button/Button";
import useContactMessages from "utils/get/FetchSupportMessages";
import BirthDate from "components/dashboard/birthDate";
import useMarkAsRead from "utils/patch/useMarkRead";
import SuccesMessage from "components/popup/SuccesMessage";
import ErrorMessage from "components/popup/ErrorMessage";

const SupportMessages = () => {
  const [visibleUnread, setVisibleUnread] = useState(2);
  const [showMoreUnread, setShowMoreUnread] = useState(false);

  const [visibleRead, setVisibleRead] = useState(2);
  const [showMoreRead, setShowMoreRead] = useState(false);

  const { messages, fetchMessages } = useContactMessages();
  const { markAsRead, error, success } = useMarkAsRead();

  const [showErrorMessage, setShowErrorMessage] = useState(true);  
  const [showSuccessMessage, setShowSuccessMessage] = useState(true); 

  const handleMarkAsRead = async (id) => {
    await markAsRead(id);
    fetchMessages();  
  };

  const handleCloseError = () => {
    setShowErrorMessage(false); 
  };

  const handleCloseSuccess = () => {
    setShowSuccessMessage(false); 
  };

  return (
    <>
      <div className="subtitle mt-2">
        <h2>Support Berichten</h2>
      </div>

      <div className="subheading mt-2">
        <h3>Ongelezen Berichten</h3>
      </div>
      <div className="messages flex-wrap gap-2 mt-2">
        {messages
          .filter((message) => !message.isRead)
          .slice(0, visibleUnread)
          .map((message) => (
            <div key={message.id} className="box message-item w-100">
              <div className="flex space-between w-100 ptb-1">
                <div className="flex message-sender">
                  <h3>
                    {message.naam} {message.achternaam}
                  </h3>
                </div>
                <Button className="button-cancel flex justify-content-end">
                  <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.01562 9.01562L27.0468 27.0468" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.01562 27.0469L27.0468 9.01565" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Button>
              </div>
              <div className="contacts mt-1">
                <h3>Contact gegevens</h3>
                <ul className="mt-1">
                  <li className="mt-1">Telefoon: {message.phone}</li>
                  <li className="mt-1">Email: {message.email}</li>
                </ul>
              </div>
              <div className="mt-2">
                <h3>Bericht</h3>
                <p className="mt-1">{message.bericht}</p>
              </div>
              <div className="flex-colomn align-items-end mt-2">
                <div className="timestamp flex">
                  <span>Verzonden op:</span> <BirthDate birthDate={message.dateSent} />
                </div>
                <div className="flex">
                  <Button
                    className={"button-choiceSec mt-1"}
                    type={"submit"}
                    text={`Email ${message.naam}`}
                    onClick={() =>
                      (window.location.href = `mailto:${message.email}`)
                    }
                  />
                  <Button className="button-delete mt-1" text="Markeer als gelezen" onClick={() => handleMarkAsRead(message.id)} />
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-content-center mt-2">
        {!showMoreUnread ? (
          <Button className="button" text="Toon meer" onClick={() => { setVisibleUnread(messages.filter(m => !m.isRead).length); setShowMoreUnread(true); }} />
        ) : (
          <Button className="button" text="Toon minder" onClick={() => { setVisibleUnread(2); setShowMoreUnread(false); }} />
        )}
      </div>

      <div className="subheading mt-2">
        <h3>Gelezen Berichten</h3>
      </div>
      <div className="messages flex-wrap gap-2 mt-2">
        {messages
          .filter((message) => message.isRead)
          .slice(0, visibleRead)
          .map((message) => (
            <div key={message.id} className="box message-item w-100 read-message">
              <div className="flex space-between w-100 ptb-1">
                <div className="flex message-sender">
                  <h3>
                    {message.naam} {message.achternaam}
                  </h3>
                </div>
              </div>
              <div className="contacts mt-1">
                <h3>Contact gegevens</h3>
                <ul className="mt-1">
                  <li className="mt-1">Telefoon: {message.phone}</li>
                  <li className="mt-1">Email: {message.email}</li>
                </ul>
              </div>
              <div className="mt-2">
                <h3>Bericht</h3>
                <p className="mt-1">{message.bericht}</p>
              </div>
              <div className="flex-colomn align-items-end mt-2">
                <div className="timestamp flex">
                  <span>Verzonden op:</span> <BirthDate birthDate={message.dateSent} />
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-content-center mt-2">
        {!showMoreRead ? (
          <Button className="button" text="Toon meer" onClick={() => { setVisibleRead(messages.filter(m => m.isRead).length); setShowMoreRead(true); }} />
        ) : (
          <Button className="button" text="Toon minder" onClick={() => { setVisibleRead(2); setShowMoreRead(false); }} />
        )}
      </div>
      {showErrorMessage && error && <ErrorMessage message={`Fout bij het markeren als gelezen: ${error}`} onClose={handleCloseError} />}
      {showSuccessMessage && success && <SuccesMessage message={"Bericht succesvol gemarkeerd als gelezen!"} onClose={handleCloseSuccess} />}
    </>
  );
};

export default SupportMessages;
