import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useChangeBackground = (basePath, backgroundColor) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith(basePath)) { // Controleer of de URL begint met de juiste route
      document.body.classList.add("background-transition");
      document.body.style.backgroundColor = backgroundColor;
    } else {
      document.body.classList.remove("background-transition");
      document.body.style.backgroundColor = "";
    }

    return () => {
      document.body.classList.remove("background-transition");
      document.body.style.backgroundColor = "";
    };
  }, [location, basePath, backgroundColor]);
};

export default useChangeBackground;
