import React, { useContext, useState } from "react";
import Button from "components/button/Button";
import { FetchContext } from "hooks/FetchContext";
import InputField from "components/inputs/InputField";
import TextareaField from "components/inputs/TextArea";
import DropDownInput from "components/inputs/Dropdown";
import SuccesMessage from "components/popup/SuccesMessage";

const CourseForm = ({ fetchCourses }) => {
  const { authAxios } = useContext(FetchContext);
  const [newCourseData, setNewCourseData] = useState({
    name: "",
    major: "",
    year: 1,
    description: "",
    educationForm: "secundair", // Set default
  });
  const [showCreateReservation, setShowCreateReservation] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleAddCourse = async () => {
    try {
      const response = await authAxios.post("/api/AdminCourse", newCourseData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        await fetchCourses();
        setNewCourseData({
          name: "",
          major: "",
          year: "",
          description: "",
          educationForm: "secundair", // Reset to default
        });

        setShowMessage("Cursus succesvol toegevoegd.");
      } else {
        alert("Fout bij het toevoegen van de cursus.");
      }
    } catch (error) {
      console.error("Fout bij het toevoegen van de cursus:", error);
    }
  };

  const toggleCreateReservation = () => {
    setShowCreateReservation(true);
  };

  return (
    <>
      <Button onClick={toggleCreateReservation}>Nieuw vak</Button>
      <div className={`side-bar ${showCreateReservation ? "show" : "hide"}`}>
        <h3>Voeg Nieuwe Cursus Toe</h3>
        {/* Dropdown voor onderwijsvorm */}
        <label htmlFor="educationForm">Onderwijsvorm</label>
        <select
          id="educationForm"
          name="educationForm"
          value={newCourseData.educationForm}
          onChange={(e) =>
            setNewCourseData({
              ...newCourseData,
              educationForm: e.target.value,
            })
          }
        >
          <option value="secundair">Secundair onderwijs</option>
          <option value="LagerOnderwijs">Lager onderwijs</option>
        </select>

        <InputField
          id="course-name"
          type="text"
          name="name"
          placeholder="Cursusnaam"
          value={newCourseData.name}
          onChange={(e) =>
            setNewCourseData({ ...newCourseData, name: e.target.value })
          }
          autoComplete="organization" // Bijvoorbeeld voor cursusnaam
        />
        <DropDownInput
          type="text"
          name="major"
          placeholder="Niveau"
          value={newCourseData.major}
          onChange={(e) =>
            setNewCourseData({ ...newCourseData, major: e.target.value })
          }
          options={[
            { value: "BSO", label: "BSO" },
            { value: "TSO", label: "TSO" },
            { value: "ASO", label: "ASO" },
            { value: "Latijn", label: "Latijn" },
            { value: "nvt", label: "Niet van Toepassing" },
          ]}
          disabled={newCourseData.educationForm === "LagerOnderwijs"}
          autoComplete="off" // Autofill uitschakelen als het niet nodig is
        />
        <TextareaField
          id="course-description"
          name="description"
          placeholder="Beschrijving"
          value={newCourseData.description}
          onChange={(e) =>
            setNewCourseData({ ...newCourseData, description: e.target.value })
          }
          autoComplete="off"
        />

        <Button onClick={handleAddCourse}>Voeg Cursus Toe</Button>
        <Button
          className={"button mt-1"}
          onClick={() => setShowCreateReservation(false)}
        >
          Annuleren
        </Button>
      </div>
      {showMessage ? (
        <SuccesMessage
          message={showMessage}
          onClose={() => setShowMessage(false)}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CourseForm;
