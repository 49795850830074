import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "hooks/AuthContext";
import { AiOutlineLogin, AiOutlineSearch, AiFillSetting } from "react-icons/ai";
import {
  FaChalkboardTeacher,
  FaUserGraduate,
  FaEnvelope,
  FaHome,
} from "react-icons/fa";

import "./Navigation.css";
import OverviewIcon from "components/button/OverviewIcon";
import ProfileIcon from "components/button/ProfileIcon";
import LessonIcon from "components/button/LessonIcon";
import AvailibleIcon from "components/button/AvailibleIcon";
import SearchIcon from "components/button/SearchIcon";
import Button from "components/button/Button";
import HeadContent from "components/header/HeadContent";

function MobileNav() {
  const { isAuthenticated, authState } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = (path) => location.pathname === path; // Strict comparison
  const isActiveDash = (path) => location.pathname.startsWith(path);

  const activeColor = "#468275"; // Active icon color
  const defaultColor = "#59b2a5"; // Default icon color
  const white = "white"; // Default icon color

  const [showPopup, setShowPopup] = useState(false);

  const handleLoginClick = () => {
    if (location.pathname === "/aanmelden") {
      setShowPopup(false);
    } else {
      navigate("/aanmelden");
    }
  };

  const handleSignUpClick = () => {
    if (location.pathname === "/registratie") {
      setShowPopup(false);
    } else {
      navigate("/registratie");
    }
  };

  const handleBijlesClick = (e) => {
    if (!isAuthenticated()) {
      e.preventDefault();
      setShowPopup(true);
    }
  };

  const handleScrollToFooter = () => {
    const footer = document.getElementById("contact");
    if (footer) {
      footer.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Check if the current path is a valid route
  const isValidRoute = [
    "/admin",
    "/beheer-gebruikers",
    "/lessenrooster",
    "/beschikbaarheden-lesgever",
    "/dashboard",
    "/mijn-profiel",
    "/mijn-bijlessen",
    "/mijn-beschikbaarheid",
    "/word-lesvolger",
    "/word-lesgever",
    "/aanmelden",
    "/bijles-zoeken",
    "/beschikbaarheden-lesgever",
    "/lessenrooster",
    "/lessenrooster/detail-reservatie/:id",
    "/beheer-gebruikers",
    "/dashboard",
  ].includes(location.pathname);

  return (
    <>
      <nav className="navbar-bottom">
        <div
          className={`navbar-mobile ${
            isAuthenticated() ? "navbar-visible" : ""
          }`}
        >
          <div className="navbar-mobile-container">
            <ul className="navbar-links flex-row align-items-center space-around ">
              {isAuthenticated() ? (
                authState.role.includes("Administrator") ? (
                  <>
                    <li
                      className={`${
                        isActiveDash("/admin") ? "active bg-primary-color" : ""
                      }`}
                    >
                      <Link to="/admin">
                        <OverviewIcon
                          size={24}
                          fill={isActiveDash("/admin") ? white : defaultColor}
                        />
                      </Link>
                    </li>
                    <li
                      className={`${
                        isActiveDash("/beheer-gebruikers")
                          ? "active bg-primary-color"
                          : ""
                      }`}
                    >
                      <Link to="/beheer-gebruikers">
                        <ProfileIcon
                          size={24}
                          fill={
                            isActiveDash("/beheer-gebruikers")
                              ? white
                              : defaultColor
                          }
                        />
                      </Link>
                    </li>
                    <li
                      className={`${
                        isActiveDash("/lessenrooster")
                          ? "active bg-primary-color"
                          : ""
                      }`}
                    >
                      <Link to="/lessenrooster">
                        <LessonIcon
                          size={24}
                          fill={
                            isActiveDash("/lessenrooster")
                              ? white
                              : defaultColor
                          }
                        />
                      </Link>
                    </li>
                    <li
                      className={`${
                        isActiveDash("/beschikbaarheden-lesgever")
                          ? "active bg-primary-color"
                          : ""
                      }`}
                    >
                      <Link to="/beschikbaarheden-lesgever">
                        <AvailibleIcon
                          size={24}
                          fill={
                            isActiveDash("/beschikbaarheden-lesgever")
                              ? white
                              : defaultColor
                          }
                        />
                      </Link>
                    </li>
                    <li
                      className={`${
                        isActiveDash("/administrator-instellingen")
                          ? "active bg-primary-color"
                          : ""
                      }`}
                    >
                      <Link to="/administrator-instellingen">
                        <AiFillSetting
                          size={24}
                          fill={
                            isActiveDash("/administrator-instellingen")
                              ? white
                              : defaultColor
                          }
                        />
                      </Link>
                    </li>
                  </>
                ) : (
                  (authState.role.includes("Student") ||
                    authState.role.includes("Teacher")) && (
                    <>
                      <li
                        className={`${
                          isActiveDash("/dashboard") || !isValidRoute
                            ? "active"
                            : ""
                        }`}
                      >
                        <Link to="/dashboard">
                          <OverviewIcon
                            size={24}
                            fill={
                              isActiveDash("/dashboard") || !isValidRoute
                                ? activeColor
                                : defaultColor
                            }
                          />
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActiveDash("/mijn-profiel") ? "active" : ""
                        }`}
                      >
                        <Link to="/mijn-profiel">
                          <ProfileIcon
                            size={24}
                            fill={
                              isActiveDash("/mijn-profiel")
                                ? activeColor
                                : defaultColor
                            }
                          />
                        </Link>
                      </li>
                      <li
                        className={`${
                          isActiveDash("/mijn-bijlessen") ? "active" : ""
                        }`}
                      >
                        <Link to="/mijn-bijlessen">
                          <LessonIcon
                            size={24}
                            fill={
                              isActiveDash("/mijn-bijlessen")
                                ? activeColor
                                : defaultColor
                            }
                          />
                        </Link>
                      </li>
                      {authState.role.includes("Student") && (
                        <li
                          className={`${
                            isActiveDash("/bijles-zoeken") ? "active" : ""
                          }`}
                        >
                          <Link to="/bijles-zoeken" onClick={handleBijlesClick}>
                            <SearchIcon
                              size={24}
                              fill={
                                isActiveDash("/bijles-zoeken")
                                  ? activeColor
                                  : defaultColor
                              }
                            />
                          </Link>
                        </li>
                      )}

                      {authState.role.includes("Teacher") && (
                        <li
                          className={`${
                            isActiveDash("/mijn-beschikbaarheid")
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link to="/mijn-beschikbaarheid">
                            <AvailibleIcon
                              size={24}
                              fill={
                                isActiveDash("/mijn-beschikbaarheid")
                                  ? activeColor
                                  : defaultColor
                              }
                            />
                          </Link>
                        </li>
                      )}
                    </>
                  )
                )
              ) : (
                <>
                  <li
                    className={`${
                      isActive("/") || !isValidRoute ? "active" : ""
                    }`}
                  >
                    <Link to="/">
                      <FaHome
                        size={24}
                        color={isActive("/") ? activeColor : activeColor}
                      />
                    </Link>
                  </li>

                  <li
                    className={` ${
                      isActive("/word-lesvolger") ? "active" : ""
                    }`}
                  >
                    <Link to="/word-lesvolger">
                      <FaChalkboardTeacher
                        size={24}
                        color={
                          isActive("/word-lesvolger") ? activeColor : "white"
                        }
                      />
                    </Link>
                  </li>
                  <li className={isActive("/word-lesgever") ? "active" : ""}>
                    <Link to="/word-lesgever">
                      <FaUserGraduate
                        size={24}
                        color={
                          isActive("/word-lesgever") ? activeColor : "white"
                        }
                      />
                    </Link>
                  </li>
                  <li className={isActive("/aanmelden") ? "active" : ""}>
                    <Link to="/aanmelden">
                      <AiOutlineLogin
                        size={24}
                        color={isActive("/aanmelden") ? activeColor : "white"}
                      />
                    </Link>
                  </li>
                  <li className={isActive("/bijles-zoeken") ? "active" : ""}>
                    <Link to="/bijles-zoeken" onClick={handleBijlesClick}>
                      <AiOutlineSearch
                        size={24}
                        color={
                          isActive("/bijles-zoeken") ? activeColor : "white"
                        }
                      />
                    </Link>
                  </li>
                  {!["/aanmelden", "/registratie"].includes(
                  location.pathname
                ) && (
                  <li
                    className={isActive("#contact") ? "active" : ""}
                    onClick={handleScrollToFooter}
                  >
                    
                    <Link to="#contact">
                      <FaEnvelope
                        size={24}
                        color={isActive("#contact") ? activeColor : "white"}
                      />
                    </Link>
                  </li>
                )}
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <HeadContent
              title="Log in of registreer."
              text="Je moet ingelogd zijn om bijles te zoeken."
              buttonText1="Aanmelden?"
              onClickMore={handleLoginClick} // Trigger navigate on click
              buttonText2="Account Aanmaken"
              onClickAction={handleSignUpClick} // Trigger navigate on click
              animationDelay={200}
              flex={false}
            />
            <Button className="button mt-1" onClick={() => setShowPopup(false)}>
              Later
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default MobileNav;
