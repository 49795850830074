import React from 'react';
// import BgStupu from "assets/img/logo-big.png";

const AppShell = ({ children }) => {
  return (
    <div
      // style={{
      //   backgroundImage: `url(${BgStupu})`,
      //   backgroundSize: 'contain',
      //   backgroundPosition: 'center',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundAttachment: 'fixed', 
      //   minWidth: '100vw',
      //   minHeight: '100vh',  

      // }}
    >
      {children}
    </div>
  );
};

export default AppShell;
