const LoginIcon = ({ fill = "white", width = 30, height = 35 }) => {
  return (
<svg width={width} height={height} viewBox="0 0 420 400" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M323.425 407.571C320.901 411.666 317.209 415.014 312.793 417.214L282.191 432.452C264.192 441.416 243.41 428.329 245.348 409.251L248.216 381.015C248.581 377.423 249.774 373.931 251.711 370.788L335.845 234.282C343.09 222.528 358.808 218.325 370.953 224.893L397.761 239.392C409.906 245.96 413.879 260.813 406.634 272.567L323.425 407.571Z" fill={fill}/>
<path fillRule="evenodd" clipRule="evenodd" d="M60 0C26.8629 0 0 26.8629 0 60V365.751C0 398.888 26.8628 425.751 59.9999 425.751H204.151L204.152 391.422C204.152 383.51 206.401 375.685 210.659 368.775L314.551 200.213C324.944 183.351 345.025 175.122 363.817 178.461V60C363.817 26.8629 336.954 0 303.817 0H60ZM90.6875 86.9374C79.6418 86.9374 70.6875 95.8917 70.6875 106.937C70.6875 117.983 79.6418 126.937 90.6875 126.937H228.319C239.365 126.937 248.319 117.983 248.319 106.937C248.319 95.8917 239.365 86.9374 228.319 86.9374H90.6875ZM70.6875 191.888C70.6875 180.842 79.6418 171.888 90.6875 171.888H190.977C202.023 171.888 210.977 180.842 210.977 191.888C210.977 202.933 202.023 211.888 190.977 211.888H90.6875C79.6418 211.888 70.6875 202.933 70.6875 191.888ZM90.6875 255.839C79.6418 255.839 70.6875 264.793 70.6875 275.839C70.6875 286.884 79.6418 295.839 90.6875 295.839H140.832C151.878 295.839 160.832 286.884 160.832 275.839C160.832 264.793 151.878 255.839 140.832 255.839H90.6875Z" fill={fill}/>
</svg>
  );
};

export default LoginIcon;
