import React from "react";
import Button from "../button/Button";
import "./SearchInput.css";
import SearchIcon from "../button/SearchIcon";

const SearchInput = ({ className, placeholder, value, onChange, id }) => {
  return (
    <div className={`search-bar flex ${className}`}>
      <input type="text" id={id} placeholder={placeholder} value={value} onChange={onChange} />
      <Button className="custom-button button-search">
        <SearchIcon />
      </Button>
    </div>
  );
};

export default SearchInput;
