// HeadContent.js
import React from "react";
import Button from "components/button/Button";
import "./HeaderHome.css";
import useScreenSize from "utils/screenSize";

const HeadContent = ({ 
  title, 
  text, 
  className, 
  buttonText1, 
  buttonText2, 
  onClickMore, 
  onClickAction, 
  flex = true,
  showButton = true,
  animationDelay,
  isVisible 
}) => {
  const animationStyle = {
    transitionDelay: `${animationDelay}ms`
  };
  const { isExtraSmallScreen } = useScreenSize();

  return (
    <div className={`block ${isVisible ? 'animate-in' : ''}`} style={animationStyle}>
      <div className="block-description">
        <h1>{title}</h1>
        <p>{text}</p>
      </div>
      <div className={`${!flex && isExtraSmallScreen ? "flex-colomn" : "flex" } mt-1`}>
        {showButton && (
          <Button
            className="custom-button"
            type="button"
            text={buttonText1}
            onClick={onClickMore}
          />
        )}
        <Button
          className="custom-button-login button-choiceSec"
          type="button"
          text={buttonText2}
          onClick={onClickAction}
        />
      </div>
    </div>
  );
};

export default HeadContent;