import React from "react";

function PrivacyPolicy() {
  return (
    <div>

      <h2 className="mt-2">Inleiding</h2>
      <p className="ptb-1">
        Bij Stupu hechten we veel waarde aan de bescherming van uw
        persoonsgegevens. In deze privacy policy leggen we uit hoe we uw
        gegevens verzamelen, gebruiken en beschermen in overeenstemming met de
        Algemene Verordening Gegevensbescherming (GDPR).
      </p>

      <h2 className="mt-2">Doelen van de gegevensverwerking</h2>
      <p className="ptb-1">
        Wij verwerken uw persoonsgegevens voor de volgende doeleinden:
      </p>
      <ul className="p-1">
        <li className="medium">
          Om u in te schrijven en toegang te verlenen tot het Stupu-platform.
        </li>
        <li className="medium">
          Om u de mogelijkheid te bieden bijlessen te geven of te volgen.
        </li>
        <li className="medium">
          Om contact met u op te nemen over belangrijke zaken met betrekking tot
          uw gebruik van het platform (zoals updates, notificaties of
          wijzigingen in onze diensten).
        </li>
        <li className="medium">
          Voor administratieve doeleinden en het beheren van uw account.
        </li>
        <li className="medium">
          Voor het verbeteren van onze diensten en het personaliseren van de
          gebruikerservaring.
        </li>
      </ul>

      <h2 className="mt-2">Verzamelde persoonsgegevens</h2>
      <p className="ptb-1">
        De gegevens die wij verzamelen kunnen het volgende omvatten:
      </p>
      <ul className="p-1">
        <li className="medium">Voornaam en achternaam</li>
        <li className="medium">E-mailadres</li>
        <li className="medium">Telefoonnummer</li>
        <li className="medium">Geboortedatum en -plaats</li>
        <li className="medium">Adres</li>
        <li className="medium">
          Opleidingsgegevens en relevante ervaring (voor bijlesgevers)
        </li>
        <li className="medium">
          Gegevens met betrekking tot geplande bijlessen (zoals tijdstip,
          locatie, onderwerp)
        </li>
        <li className="medium">Betalingsgegevens (indien van toepassing)</li>
      </ul>

      <h2 className="mt-2">Rechtsgrond voor verwerking</h2>
      <p className="ptb-1">Wij verwerken uw persoonsgegevens op basis van:</p>
      <ul className="p-1">
        <li className="medium">
          Uw toestemming, die u kunt intrekken wanneer u maar wilt.
        </li>
        <li className="medium">
          De uitvoering van de overeenkomst die wij met u aangaan, bijvoorbeeld
          wanneer u zich aanmeldt voor het platform of gebruik maakt van onze
          diensten.
        </li>
        <li className="medium">
          De naleving van juridische verplichtingen die wij moeten volgen.
        </li>
      </ul>

      <h2 className="mt-2">Bewaartermijn van gegevens</h2>
      <p className="ptb-1">
        Uw persoonsgegevens worden niet langer bewaard dan nodig is voor de
        doeleinden waarvoor ze zijn verzameld. Wij bewaren uw gegevens zolang u
        een account bij ons heeft, of zolang als nodig is om te voldoen aan
        wettelijke verplichtingen, zoals belastingwetgeving.
      </p>

      <h2 className="mt-2">Beveiliging van gegevens</h2>
      <p className="ptb-1">
        Stupu neemt passende technische en organisatorische maatregelen om uw
        persoonsgegevens te beschermen tegen verlies, diefstal, misbruik of
        ongeoorloofde toegang. Onze systemen worden regelmatig gecontroleerd en
        geüpdatet om de veiligheid van uw gegevens te waarborgen.
      </p>

      <h2 className="mt-2">Rechten van betrokkenen</h2>
      <ul className="p-1">
        <li className="medium">Inzage te vragen in uw persoonsgegevens.</li>
        <li className="medium">
          Correctie of verwijdering van uw persoonsgegevens te verzoeken.
        </li>
        <li className="medium">Beperking van de verwerking te vragen.</li>
        <li className="medium">Uw gegevens over te dragen.</li>
        <li className="medium">
          Tegen de verwerking van uw gegevens bezwaar te maken.
        </li>
      </ul>

      <h2 className="mt-2">Contact</h2>
      <p className="ptb-1">
        Als u vragen heeft over deze privacy policy of uw persoonsgegevens, kunt
        u contact met ons opnemen via:
      </p>
      <ul>
        <li className="medium">
          E-mailadres: <a href="mailto:noud@stupu.be">noud@stupu.be</a>
        </li>
        <li className="medium">
          Telefoonnummer: <a href="tel:+32 473 82 03 18">+32 473 82 03 18</a>
        </li>
      </ul>

      <h2 className="mt-2">Wijzigingen in het privacy beleid</h2>
      <p className="ptb-1">
        We behouden ons het recht voor om deze privacy policy te wijzigen.
        Wanneer er belangrijke wijzigingen worden aangebracht, zullen we u
        hierover informeren. We adviseren u om deze policy regelmatig te
        raadplegen.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
