import React, { lazy, Suspense, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider, AuthContext } from "./hooks/AuthContext";
import { FetchProvider } from "./hooks/FetchContext";
import { CartProvider } from "hooks/CartContext";
import ScrollToTop from "utils/ScrollToTop";
import AppShell from "./AppShell";
import "./styles/index.css";
import Settings from "pages/dashboard/admin/Settings";
import ErrorLoginGebruiker from "pages/main/404/404-login-gebruiker";
import ConditionsStuPu from "pages/main/conditions/conditions";
import Policy from "pages/main/conditions/policy";
import CookieConsent from "components/cookies/CookieConsent";

// Main
const Home = lazy(() => import("./pages/main/home/home"));
const InfoStudent = lazy(() => import("./pages/main/info/student"));
const InfoTutor = lazy(() => import("./pages/main/info/tutor"));
const Dashboard = lazy(() => import("./pages/dashboard/users/Overview"));
const SignIn = lazy(() => import("./pages/main/login/signIn"));
const ResetPwd = lazy(() => import("./pages/main/login/ResetPwd"));

const Signup = lazy(() => import("./pages/main/registration/signupStudent"));
const SignUpTutor = lazy(() => import("./pages/main/registration/signupTutor"));
const SignUpChoice = lazy(() =>
  import("./pages/main/registration/signupChoice")
);
const EmailVerification = lazy(() =>
  import("./pages/main/verfications/EmailVerification")
);
const PaymentComfirmed = lazy(() =>
  import("./pages/booking/add-to-cart/payment-succes")
);
const Loader = lazy(() => import("./components/loader/Loader"));
const Admin = lazy(() => import("./pages/dashboard/admin/Overview"));
const Users = lazy(() => import("./pages/dashboard/admin/User"));
const DetailManagement = lazy(() =>
  import("./pages/dashboard/admin/DetailManagement")
);
const DetailRegistrations = lazy(() =>
  import("./pages/dashboard/admin/DetailRegistrations")
);
const Schedual = lazy(() => import("./pages/dashboard/admin/Schedual"));
const SchedualDetail = lazy(() =>
  import("./pages/dashboard/admin/SchedualDetail")
);
const Availability = lazy(() => import("./pages/dashboard/admin/Availability"));
const DetailAvailability = lazy(() =>
  import("./pages/dashboard/admin/DetailAvailability")
);
const MyLessonsStudent = lazy(() =>
  import("./pages/dashboard/users/MyLessons")
);
const LessonDetail = lazy(() => import("./pages/dashboard/users/LessonDetail"));
const MyProfile = lazy(() => import("./pages/dashboard/users/MyProfile"));
const AvailabilityManager = lazy(() =>
  import("./pages/dashboard/tutor/Available")
);
const Search = lazy(() => import("./pages/booking/booking-tutor/booking"));
const DetailBooking = lazy(() =>
  import("./pages/booking/booking-tutor/detailBooking")
);

const Cart = lazy(() => import("./pages/booking/add-to-cart/checkout"));

const LoadingFallback = () => (
  <AppShell>
    <Loader />
  </AppShell>
);

const UnauthenticatedRoutes = () => (
  <Routes>
        <Route path="/privacy-beleid" element={<Policy />} />
    <Route path="/algemene-voorwaarden" element={<ConditionsStuPu />} />
    <Route path="/email-verificatie" element={<EmailVerification />} />
    <Route path="/wachtwoord-wijzigen" element={<ResetPwd />} />
    <Route path="/registratie" element={<SignUpChoice />} />
    <Route path="/registratie-lesgever" element={<SignUpTutor />} />
    <Route path="/registratie-lesvolger" element={<Signup />} />
    <Route path="/aanmelden" element={<SignIn />} />
    <Route path="/" element={<Home />} />
    <Route path="/word-lesvolger" element={<InfoStudent />} />
    <Route path="/word-lesgever" element={<InfoTutor />} />
    <Route path="/betaling-succesvol" element={<PaymentComfirmed />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

const AuthenticatedRoute = ({ children }) => {
  const auth = useContext(AuthContext);
  if (!auth.isAuthenticated()) {
    return <Navigate to="/aanmelden" />;
  }

  if (auth.isAdmin()) {
    return <Navigate to="/admin" />;
  }

  return <AppShell>{children}</AppShell>;
};

const AdminRoute = ({ children }) => {
  const auth = useContext(AuthContext);
  return auth.isAuthenticated() && auth.isAdmin() ? (
    <AppShell>{children}</AppShell>
  ) : (
    <Navigate to="/aanmelden" />
  );
};

const TutorRoute = ({ children }) => {
  const auth = useContext(AuthContext);
  return auth.isAuthenticated() && auth.isTutor() ? (
    <AppShell>{children}</AppShell>
  ) : (
    <Navigate to="/aanmelden" />
  );
};

const StudentRoute = ({ children }) => {
  const auth = useContext(AuthContext);
  return auth.isAuthenticated() && auth.isStudent() ? (
    <AppShell>{children}</AppShell>
  ) : (
    <Navigate to="/aanmelden" />
  );
};

const UserRoute = ({ children }) => {
  const auth = useContext(AuthContext);
  return auth.isAuthenticated() && auth.isUser() ? (
    <AppShell>{children}</AppShell>
  ) : (
    <Navigate to="/aanmelden" />
  );
};


const AppRoutes = () => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <AuthenticatedRoute>
              <Dashboard />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/bijles-zoeken"
          element={
            <StudentRoute>
              <Search />
            </StudentRoute>
          }
        />

        <Route
          path="/mijn-bijlessen"
          element={
            <AuthenticatedRoute>
              <MyLessonsStudent />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/mijn-bijles-detail/:id"
          element={
            <AuthenticatedRoute>
              <LessonDetail />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/mijn-profiel"
          element={
            <AuthenticatedRoute>
              <MyProfile />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/bijles-zoeken/docent/:id"
          element={
            <AuthenticatedRoute>
              <DetailBooking />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/mijn-beschikbaarheid"
          element={
            <TutorRoute>
              <AvailabilityManager />
            </TutorRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <AdminRoute>
              <Admin />
            </AdminRoute>
          }
        />
        <Route
          path="/beheer-gebruikers"
          element={
            <AdminRoute>
              <Users />
            </AdminRoute>
          }
        />
        <Route
          path="/beheer-gebruikers/gebruiker/:id" // Match both name and id
          element={
            <AdminRoute>
              <DetailManagement />
            </AdminRoute>
          }
        />
        <Route
          path="/beheer-gebruikers/registraties-docenten/:id" // Match both name and id
          element={
            <AdminRoute>
              <DetailRegistrations />
            </AdminRoute>
          }
        />
        <Route
          path="/lessenrooster"
          element={
            <AdminRoute>
              <Schedual />
            </AdminRoute>
          }
        />
        <Route
          path="/lessenrooster/detail-reservatie/:id"
          element={
            <AdminRoute>
              <SchedualDetail />
            </AdminRoute>
          }
        />
        <Route
          path="/beschikbaarheden-lesgever"
          element={
            <AdminRoute>
              <Availability />
            </AdminRoute>
          }
        />
        <Route
          path="/beschikbaarheden-lesgever/:id" // This matches the dynamic detail route
          element={
            <AdminRoute>
              <DetailAvailability />
            </AdminRoute>
          }
        />
        <Route
          path="/administrator-instellingen" // This matches the dynamic detail route
          element={
            <AdminRoute>
              <Settings />
            </AdminRoute>
          }
        />

        <Route
          path="/checkout"
          element={
            <AuthenticatedRoute>
              <Cart />
            </AuthenticatedRoute>
          }
        />
        
        <Route
          path="/404-verificatie-docent"
          element={
            <UserRoute>
              <ErrorLoginGebruiker />
            </UserRoute>
         }
        />

        <Route path="/*" element={<UnauthenticatedRoutes />} />
      </Routes>
    </Suspense>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AuthProvider>
        <FetchProvider>
          <CartProvider>
            <AppRoutes />
            <CookieConsent />
          </CartProvider>
        </FetchProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
