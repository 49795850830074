import { useCart } from "hooks/CartContext";
import { FaShoppingCart } from "react-icons/fa"; // Use FontAwesome or any icon library
import { Link } from "react-router-dom";
import './CartCount.css';

export default function CartIcon() {
  const { cartCount } = useCart();
  return (
      <div className="shopping-cart">
      <Link to={'/checkout'}>      
      <FaShoppingCart size={24} />
        {cartCount > 0 && (
          <span className="cart-count -top-2 -right-2 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
            {cartCount}
          </span>
        )}
      </Link>
      </div>      

  );
}
