import React, { createContext, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext"; // Adjust path if needed

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const { authState, logout } = useContext(AuthContext);

  const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });


  authAxios.interceptors.request.use(
    (config) => {
      if (authState.token) {
        config.headers.Authorization = `Bearer ${authState.token}`;
      }
      config.withCredentials = true;
      return config;
    },
    (error) => Promise.reject(error)
  );

  authAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  return (
    <Provider value={{ authAxios }}>
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };
