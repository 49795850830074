import Conditions from "layouts/main/conditions/conditions";
import Footer from "layouts/main/footer/Footer";
import DesktopNav from "layouts/navigations/DesktopNav";
import MobileNav from "layouts/navigations/MobileNav";
import React from "react";

function ConditionsStuPu() {
  return (
    <>
      <MobileNav />
      <div className="cstm-container">
        <DesktopNav />
        <div className="title">
          <h1>Algemene Voorwaarden</h1>
        </div>
        <Conditions />
        <Footer />
      </div>
    </>
  );
}

export default ConditionsStuPu;
