import Button from "components/button/Button";
import React from "react";
import "./Message.css";
import { IoAlertCircle } from "react-icons/io5";

const ErrorMessage = ({ message, onClose }) => {
  return (
    <div className="popup-overlay" aria-live="assertive">
      <div className="popup-content">
        <div className="flex-column align-items-center">
          <IoAlertCircle size={50} />
        </div>
        <div className="mt-1">
          {message}
        </div>
        <Button className="button-choiceSec mt-2" onClick={onClose}>
          Sluiten
        </Button>
      </div>
    </div>
  );
};

export default ErrorMessage;
