import React, { useCallback, useContext, useEffect, useState } from "react";
import { FetchContext } from "hooks/FetchContext";
import UserTable from "components/dashboard/table/Table";
import PopUp from "components/popup/PopUp";
import { FaCheck, FaTimes, FaLock, FaLockOpen } from "react-icons/fa";
import SuccesMessage from "components/popup/SuccesMessage";
import ErrorMessage from "components/popup/ErrorMessage";
import SearchInput from "components/inputs/SearchInput";
import DropDownInput from "components/inputs/Dropdown";
import Button from "components/button/Button";
import EditableIcon from "components/button/EditIcon";
import CourseForm from "./createCourse";
import useScreenSize from "utils/screenSize";

const sortingOptions = [
  { value: "all", label: "Alle" },
  { value: "active", label: "Actief" },
  { value: "inactive", label: "Non-actief" },
  { value: "secundair", label: "Secundair Onderwijs" },
  { value: "lagerOnderwijs", label: "Lager Onderwijs" },
];

const AdminCourseSettings = () => {
  const { authAxios } = useContext(FetchContext);
  const [courses, setCourses] = useState([]);
  const [editingCourse, setEditingCourse] = useState(null);
  const [formData, setFormData] = useState({});
  const [succes, setSucces] = useState(null);
  const [error, setError] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const {isSmallScreen }= useScreenSize();

  const fetchCourses = useCallback(async () => {
    try {
      const response = await authAxios.get("/api/AdminCourse");
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  }, [authAxios]); // Add authAxios as a dependency if it changes

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  const handleEdit = (course) => {
    setEditingCourse(course.id);
    setFormData(course);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDeactivate = async (id, activeState) => {
    try {
      const response = await authAxios.put(
        `/api/AdminCourse/deactivate/${id}?active=${activeState}`
      );

      if (response.status === 200) {
        fetchCourses();
        setSucces(
          (activeState === true
            ? " Vak succevol geactiveerd "
            : "Vak succevol gedeactiveerd")
        );
      } else {
        setError("An error occurred while deactivating the course.");
      }
    } catch (error) {
      setError(`Error: ${error.response?.data?.message || "Unknown error"}`);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await authAxios.put(
        `/api/AdminCourse/${editingCourse}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setSucces("Bewerkt");
        setEditingCourse(null);
        fetchCourses();
      } else {
        setError("An error occurred while deactivating the course.");
      }
    } catch (error) {
      setError(`Error: ${error.response?.data?.message || "Unknown error"}`);
    }
  };

  const filteredCourses = courses
    .filter((course) => {
      const matchesSearch = course.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesStatus =
        filterStatus === "all" ||
        (filterStatus === "active" && course.isActive) ||
        (filterStatus === "inactive" && !course.isActive) ||
        (filterStatus === "secundair" &&
          course.educationForm === "secundair") ||
        (filterStatus === "lagerOnderwijs" &&
          course.educationForm === "lagerOnderwijs");
      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => (b.isActive ? 1 : -1) - (a.isActive ? 1 : -1));

  const columns = [
    { key: "educationForm", header: "Onderwijsvorm" },
    { key: "name", header: "Naam" },
    { key: "major", header: "Niveau" },
    {
      key: "actions",
      header: "Acties",
      render: (_, course) => (
        <div className="flex">
                  <Button className={"button-edit"} onClick={() => handleEdit(course)}>
            <EditableIcon width={20} />
          </Button>
          <Button
            className={"button-edit"}
            onClick={() => handleDeactivate(course.id, true)} // Activate course
          >
            <FaLockOpen width={20} />
          </Button>
          <Button
            className={"button-edit"}
            onClick={() => handleDeactivate(course.id, false)} // Deactivate course
          >
            <FaLock width={20} />
          </Button>
        </div>
      ),
    },
    {
      key: "isActive",
      header: "Actief",
      render: (_, course) => (
        <span>
          {course.isActive ? (
            <FaCheck color="green" />
          ) : (
            <FaTimes color="#8b0000" />
          )}
        </span>
      ),
    },
  ];

  return (
    <div>
      <div className="subtitle">
        <h2>Vak instellingen</h2>
      </div>

      <div className={`${isSmallScreen ? "flex-colomn" : "flex"} mt-2`} >
        <SearchInput
                id="unique-search-input-2"
          type="text"
          placeholder="Zoek op naam, email, telefoon..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <DropDownInput
          name="sortOrder"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          options={sortingOptions}
        />
        <CourseForm fetchCourses={fetchCourses} />
      </div>

      <UserTable data={filteredCourses} columns={columns} />

      {editingCourse && (
        <PopUp onCancel={() => setEditingCourse(null)}>
          <div>
            <h3>Edit Course</h3>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              type="text"
              name="major"
              value={formData.major}
              onChange={handleChange}
            />
            <input
              type="number"
              name="year"
              value={formData.year}
              onChange={handleChange}
            />
            <button onClick={handleUpdate}>Save</button>
          </div>
        </PopUp>
      )}

      {succes && (
        <SuccesMessage onClose={() => setSucces(null)} message={succes} />
      )}
      {error && <ErrorMessage onClose={() => setError(null)} message={error} />}
    </div>
  );
};

export default AdminCourseSettings;
