import Button from "components/button/Button";
import { AuthContext } from "hooks/AuthContext";
import { useContext } from "react";
import { IoAlertCircle } from "react-icons/io5";
import useScreenSize from "utils/screenSize";

const ErrorLoginGebruiker = () => {
  const { logout } = useContext(AuthContext);

  const { isSmallScreen , isExtraSmallScreen} = useScreenSize();

  const handleSignOut = () => {
    logout();
  };

  return (
    <div className="popup">
      <div className={`${isExtraSmallScreen ?  "flex-colomn align-items-center " : ""} p-1`}>
        <div className={`${isExtraSmallScreen ?  "flex-colomn align-items-center" : "flex "}`}>
            <IoAlertCircle className="check-icon" size={60} color="orange" />
            <h2 className={`${isExtraSmallScreen ?  "text-align-center" : "mr-1 "}`}>
            In afwachtening op verficatie van jouw gegevens...
            </h2>            
        </div>
        <p className={`${isExtraSmallScreen ?  "mt-1" : ""}`} >
          Een mail zal worden verstuurd van zodra u bent
          geaccepteerd als docent...
        </p>
        <Button
          className={`mt-2 ${isSmallScreen ? "w-100" : " "}`}
          onClick={handleSignOut}
        >
          Afmelden
        </Button>
      </div>
    </div>
  );
};

export default ErrorLoginGebruiker;
