const FacebookIcon = ({ strokeColor = "white", size = 100 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M74.9998 8.33325H62.4998C56.9745 8.33325 51.6755 10.5282 47.7684 14.4352C43.8614 18.3422 41.6665 23.6412 41.6665 29.1666V41.6666H29.1665V58.3333H41.6665V91.6666H58.3332V58.3333H70.8332L74.9998 41.6666H58.3332V29.1666C58.3332 28.0615 58.7722 27.0017 59.5536 26.2203C60.335 25.4389 61.3948 24.9999 62.4998 24.9999H74.9998V8.33325Z"
        stroke={strokeColor}
        strokeWidth="9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FacebookIcon;
