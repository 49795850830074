import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "hooks/AuthContext";
import { FetchContext } from "hooks/FetchContext"; // Import FetchContext here
import fetchUserData from "utils/get/FetchUserData";
import DefaultAvatar from "assets/img/defaultprofile.webp";
import { MdLogout } from "react-icons/md"; // Logout icon
import "./ProfileDropdown.css";
import LoginIcon from "components/button/LoginIcon";

const ProfileDropdown = ({ onLogout }) => {
  const {  logout } = useContext(AuthContext);
  const { authAxios } = useContext(FetchContext); // Use context here

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchUserData(authAxios); // Pass authAxios as argument
        setUserData(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [authAxios]); // Make sure authAxios is included in dependencies

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const handleSignOut = () => {
    logout();
    if (onLogout) onLogout();
  };

  return (
    <div className="dropdown">
      <div className="user">
        {!userData.profilePicturePath ? (       
          <LoginIcon />
        ) : (
          <img
            className="user-img"
            src={userData.profilePicturePath || DefaultAvatar}
            alt={`user: ${userData.firstName || "default"}`}
          />
        )}
        <div className="user-name">{userData.firstName}{userData.lastName} </div>
        <button className="logout-btn" onClick={handleSignOut} title="Uitloggen">
          <MdLogout size={24} color="white" />
        </button>
      </div>
    </div>
  );
};

export default ProfileDropdown;
