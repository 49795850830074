const FetchUserData = async (authAxios) => {
  try {
    const response = await authAxios.get("/api/User/me");
    return response.data;
  } catch (error) {
    throw error; // Handle or log error as needed
  }
};

export default FetchUserData;
