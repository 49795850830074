import { useState, useEffect, useRef, useContext } from "react";
import Facebook from "components/button/facebookIcon";
import Instagram from "components/button/Instagram";
import { Link } from "react-router-dom";
import { AuthContext } from "hooks/AuthContext";

const Footer = () => {
  const [widthScreen, setWidthScreen] = useState(window.innerWidth < 567);
  const { isAuthenticated } = useContext(AuthContext);


  useEffect(() => {
    const handleResize = () => {
      setWidthScreen(window.innerWidth < 567);
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const socialRef = useRef(null);
  const linksRef = useRef(null);
  const copyRef = useRef(null);
  const creatorRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-in3");
          }
        });
      },
      {
        threshold: 0.2,
        rootMargin: "0px",
      }
    );

    const socialElement = socialRef.current; // Store the current value of ref
    const linksElement = linksRef.current; // Store the current value of ref
    const copyElement = copyRef.current; // Store the current value of ref
    const creatorElement = creatorRef.current; // Store the current value of ref

    if (socialElement) {
      observer.observe(socialElement);
    }
    if (linksElement) {
      observer.observe(linksElement);
    }
    if (copyElement) {
      observer.observe(copyElement);
    }
    if (creatorElement) {
      observer.observe(creatorElement);
    }

    return () => {
      if (socialElement) {
        observer.unobserve(socialElement); // Use the stored value
      }
      if (linksElement) {
        observer.unobserve(linksElement); // Use the stored value
      }
      if (copyElement) {
        observer.observe(copyElement);
      }
      if (creatorElement) {
        observer.observe(creatorElement);
      }
    };
  }, []); // Empty dependency array since refs are static

  return (
    <section className={`Footer ${isAuthenticated() ? "p-footer" : ""}`}>
      <div className={widthScreen ? "flex-colomn" : "flex-row"}>
        <div className="socials w-100 content-animation" ref={socialRef}>
          <div className="subtitle">
            <h2>Socials</h2>
          </div>
          <div className="flex  w-100 ptb-1 mt-1">
            <div className="footer-icon ">
              <Link to={"https://www.instagram.com/stupube/"} target="_blank">
                <Instagram size={125} />
              </Link>
            </div>
            <div className="footer-icon">
              <Link
                to={
                  "https://www.facebook.com/profile.php?id=61566001246716&locale=nl_NL"
                }
                target="_blank"
              >
                <Facebook size={125} />
              </Link>
            </div>
          </div>
        </div>

        <div className="w-100 content-animation-reverse" ref={linksRef}>
          <div className="subtitle">
            <h2>About</h2>
          </div>
          <ul className="footer-links ptb-1 4">
            <li className="mt-1">
              <Link to={"/word-lesvolger"}>Word Student!</Link>
            </li>
            <li className="mt-1">
              <Link to={"/word-lesgever"}>Word Leerkracht!</Link>
            </li>
            <li className="mt-1">
              <Link to={"/algemene-voorwaarden"}>Algemene Voorwaarden</Link>
            </li>
            <li className="mt-1">
              <Link to={"/privacy-beleid"}>Privacybeleid</Link>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`mt-2 ${
          widthScreen ? "flex-colomn align-items-end" : "flex-row space-between"
        } `}
      >
        <p className="content-animation" ref={copyRef}>
          &copy;2025 Stupu. Alle rechten voorbehouden.
        </p>
        <Link
          className="content-animation-reverse"
          to={"https://delpdesign.com/"}
          ref={creatorRef}
        >
          Designed by DelpDesign
        </Link>
      </div>
    </section>
  );
};

export default Footer;
