const Footer = () => {
  return (
    <div className="box-12 flex-column align-items-end box-support p-footer">
      <h2>Hulp nodig?</h2>
      <div className="ptb-1">
        Telefoon: <a href="tel:0477889944">+32 473 82 03 18</a>
      </div>
      <div>
        Email: <a href="mailto:info@stupu.be">info@stupu.be</a>
      </div>
    </div>
  );
};

export default Footer;
