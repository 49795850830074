import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom"; // Import Link here
import { AuthContext } from "hooks/AuthContext";
import "./Navigation.css";
import Logo from "assets/img/logo.png";
import Button from "components/button/Button";
import ProfileDropdown from "./ProfileDropdown";
import LoginIcon from "components/button/LoginIcon";
import SignUpIcon from "components/button/SignUpIcon";
import CartIcon from "layouts/booking/add-to-card/CartCount";
import Stats from "layouts/dashboard/admin/overview/Statistics";
import CourseInput from "layouts/booking/booking-tutor/CourseSearchInput";
import HeadContent from "components/header/HeadContent";
import StupuPoints from "./StupuPoints";

function DesktopNav() {
  const { isAuthenticated, isStudent, authState } = useContext(AuthContext);

  const navigate = useNavigate();

  const [widthScreen, setWidthScreen] = useState(window.innerWidth < 776);
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  const isActive = (path) => location.pathname === path;
  const activeColor = "#468275"; // Active icon color

  const handleScrollToFooter = () => {
    const footer = document.getElementById("contact");
    if (footer) {
      footer.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLoginClick = () => {
    if (location.pathname === "/aanmelden") {
      setShowPopup(false);
    } else {
      navigate("/aanmelden");
    }
  };

  const handleSignUpClick = () => {
    if (location.pathname === "/registratie") {
      setShowPopup(false);
    } else {
      navigate("/registratie");
    }
  };

  const handleBijlesClick = (e) => {
    if (!isAuthenticated()) {
      e.preventDefault();
      setShowPopup(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidthScreen(window.innerWidth < 776);
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-flex">
        <div
          className={`${
            widthScreen
              ? "flex-column justify-content-center"
              : "flex space-between"
          } navbar-links`}
        >
          <div className="navbar-logo">
            <div className="navbar-logo">
              <Link to={isAuthenticated() ? "/dashboard" : "/"}>
                <img src={Logo} alt="Logo StuPu" />
              </Link>
            </div>
          </div>
          <div className="navbar-menu w-100">
            {isAuthenticated() ? (
              <>
                <div
                  className={`${
                    widthScreen
                      ? "justify-content-center"
                      : "flex space-between"
                  } navbar-links`}
                >
                  {authState.role.includes("Administrator") ? (
                    <Stats />
                  ) : isAuthenticated() && isStudent() ? (
                    <div className={`${widthScreen ? "mt-1" : ""}`}>
                    <CourseInput />
                      </div>
                  ) : (
                    <div> </div>
                  )}
                  <div className={`flex gap-2 ${widthScreen ? "mt-1 justify-content-center" : ""}   `}>
                    {isAuthenticated() && isStudent() ? <StupuPoints /> : null}
                    {isAuthenticated() && isStudent() ? <CartIcon /> : null}
                    <ProfileDropdown />
                  </div>
                </div>
              </>
            ) : (
              <ul className="navbar-desktop">
                <li>
                  <Link to="/word-lesvolger">Word Lesvolger!</Link>
                </li>
                <li>
                  <Link to="/word-lesgever">Word Lesgever</Link>
                </li>
                <li>
                  <Link to="/bijles-zoeken" onClick={handleBijlesClick}>
                    Bijles zoeken
                  </Link>
                </li>
                {!["/aanmelden", "/registratie"].includes(
                  location.pathname
                ) && (
                  <li onClick={handleScrollToFooter}>
                    <Link to="#contact">Contact & Socials</Link>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>

        <div className="navbar-signup flex">
          {isAuthenticated() ? (
            <></>
          ) : (
            <>
              <Button
                className="custom-button button-login button-desktop flex align-items-center m-05 "
                type="button"
                text="aanmelden"
                onClick={handleLoginClick}
              >
                <LoginIcon />
              </Button>
              <Button
                className="custom-button button-registration button-choiceSec button-desktop flex align-items-center m05 "
                type="button"
                text="registreer"
                onClick={handleSignUpClick}
              >
                <SignUpIcon />
              </Button>
              <div className="navbar-signup-mobile">
                <Button
                  className="custom-button button-registration button-choiceSec button-desktop flex align-items-center m05 "
                  type="button"
                  text="registreer"
                  onClick={handleSignUpClick}
                >
                  <SignUpIcon />
                </Button>
              </div>
            </>
          )}
        </div>

        {isAuthenticated() ? (
          <></>
        ) : (
          <div className="navbar-signup-mobile">
            <Button
              className={`navbar-mobile-button ${
                isActive("/registratie") ? "active" : ""
              } button-registration button-desktop flex align-items-center m05 `}
              type="button"
              text="registreer"
              onClick={handleSignUpClick}
            >
              <SignUpIcon
                fill={isActive("/registratie") ? activeColor : "white"}
              />
            </Button>
          </div>
        )}
      </div>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <HeadContent
              title="Log in of registreer."
              text="Je moet ingelogd zijn om bijles te zoeken."
              buttonText1="Aanmelden?"
              onClickMore={handleLoginClick} // Trigger navigate on click
              buttonText2="Account Aanmaken"
              onClickAction={handleSignUpClick} // Trigger navigate on click
              animationDelay={200}
            />
            <Button className="button mt-1" onClick={() => setShowPopup(false)}>
              Later
            </Button>
          </div>
        </div>
      )}
    </nav>
  );
}

export default DesktopNav;
