import ArrowIcon from "components/button/ArrowIcon";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Table.css"; // Renamed CSS file for clarity

const UserTable = ({ className, data, columns, getLink }) => {
  const navigate = useNavigate();

  const handleRowClick = (item) => {
    if (getLink) {
      const link = getLink(item); // Generate the dynamic link
      navigate(link, { state: { data: item } }); // Pass the selected row data in the state
    }
  };

  return (
    <div className="user-table-relative">
      <div className={`user-table-container ${className}`}>
        <table className="user-table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>{column.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr
                key={item.id}
                className="user-table-row clickable-row"
                onClick={() => handleRowClick(item)}
              >
                {columns.map((column) => (
                  <td
                    key={column.key}
                    className={column.key === "email" ? "email-column" : ""}
                  >
                    {column.render
                      ? column.render(item[column.key], item)
                      : item[column.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {data.length > 3 ? <div className="scroll-arrow"><ArrowIcon width={20} height={20} fill="#59b2a5" direction="bottom"/></div> : ""}
        
      </div>
    </div>

  );
};

export default UserTable;
