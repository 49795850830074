const Instagram = ({ strokeColor = "white", size = 100 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.9168 27.0833H72.9585M29.1668 8.33325H70.8335C82.3394 8.33325 91.6668 17.6607 91.6668 29.1666V70.8332C91.6668 82.3392 82.3394 91.6666 70.8335 91.6666H29.1668C17.6609 91.6666 8.3335 82.3392 8.3335 70.8332V29.1666C8.3335 17.6607 17.6609 8.33325 29.1668 8.33325ZM66.6668 47.3749C67.181 50.8426 66.5887 54.3842 64.9742 57.4958C63.3596 60.6075 60.8049 63.1308 57.6736 64.7069C54.5423 66.283 50.9937 66.8316 47.5326 66.2746C44.0715 65.7177 40.8742 64.0836 38.3953 61.6048C35.9165 59.1259 34.2824 55.9286 33.7254 52.4675C33.1685 49.0064 33.7171 45.4578 35.2932 42.3265C36.8693 39.1951 39.3926 36.6405 42.5043 35.0259C45.6159 33.4113 49.1575 32.819 52.6252 33.3333C56.1623 33.8578 59.437 35.506 61.9656 38.0345C64.4941 40.563 66.1423 43.8377 66.6668 47.3749Z"
        stroke={strokeColor}
        strokeWidth="9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Instagram;
