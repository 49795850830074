const OverviewIcon = ({ fill = "white", size = 40}) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 413 450"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M361.07 336.224C361.07 370.633 333.253 398.448 299.035 398.448C264.817 398.448 237 370.633 237 336.224C237 301.815 264.817 274 299.035 274C333.253 274 361.07 301.815 361.07 336.224Z"
          stroke={fill}
          strokeWidth="40"
        />
        <path
          d="M395.007 431.991L347.277 384.591"
          stroke={fill}
          strokeWidth="35"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60 0C26.8629 0 0 26.8629 0 60V365.751C0 398.888 26.8628 425.751 59.9999 425.751H226.402C198.788 404.471 180.99 371.026 180.99 333.414C180.99 269.126 232.986 217.011 297.125 217.011C321.944 217.011 344.944 224.814 363.817 238.106V60C363.817 26.8629 336.954 0 303.817 0H60ZM90.6875 86.9374C79.6418 86.9374 70.6875 95.8917 70.6875 106.937C70.6875 117.983 79.6418 126.937 90.6875 126.937H228.319C239.365 126.937 248.319 117.983 248.319 106.937C248.319 95.8917 239.365 86.9374 228.319 86.9374H90.6875ZM70.6875 191.888C70.6875 180.842 79.6418 171.888 90.6875 171.888H190.977C202.023 171.888 210.977 180.842 210.977 191.888C210.977 202.933 202.023 211.888 190.977 211.888H90.6875C79.6418 211.888 70.6875 202.933 70.6875 191.888ZM90.6875 255.839C79.6418 255.839 70.6875 264.793 70.6875 275.839C70.6875 286.884 79.6418 295.839 90.6875 295.839H140.832C151.878 295.839 160.832 286.884 160.832 275.839C160.832 264.793 151.878 255.839 140.832 255.839H90.6875Z"
          fill={fill}
        />
      </svg>
    );
  };
  
  export default OverviewIcon;
  