import Button from "components/button/Button";
import React from "react";
import "./Message.css";
import { IoCheckmarkCircle } from "react-icons/io5";

const SuccesMessage = ({ message, onClose , children}) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <p className="flex-column space-between align-items-center">
          <span className="flex justify-content-center ptb-1">
            <IoCheckmarkCircle size={50} color="green" />
          </span>
        </p>
          <div>{message}</div>
        {children}

        <Button className={"button-choiceSec mt-2"} onClick={onClose}>
          Sluiten
        </Button>
      </div>
    </div>
  );
};

export default SuccesMessage;
