import { useState, useEffect, useContext } from "react";
import { FetchContext } from "hooks/FetchContext";
import "./Statistics.css";


const Stats = () => {
  const { authAxios } = useContext(FetchContext);
  const [dataUsers, setDataUsers] = useState([]);
  const [dataReservations, setDataReservations] = useState([]);
  const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRegistrations = async () => {
          try {
            const response = await authAxios.get("/api/AdminUser");
            setDataUsers(response.data);
          } catch (err) {
            alert("Failed to fetch data. Please try again later.");
          } finally {
            setLoading(false);
          }
        };
    
        fetchRegistrations();
      }, [authAxios]);



      useEffect(() => {
        const fetchReservations = async () => {
          try {
            const response = await authAxios.get("/api/AdminReservation", {
              headers: {
                "Content-Type": "application/json",
              },
            });
    
            if (response.status === 200) {
              setDataReservations(response.data); // Set the fetched lessons in state
            } else {
              throw new Error("Failed to fetch lessons.");
            }
          } catch (err) {
            alert("Failed to load lessons. Please try again later.");
          } finally {
            setLoading(false); // Stop the loading spinner after fetching or error
          }
        };
    
        fetchReservations();
      }, [authAxios]);


   const countUsers = dataUsers.length;
   const countReservations = dataReservations.length;


    return(
        <div className="stats">
            <div className="flex justify-content-center">
                <div className="stats-item">
                    <div className="stats-amount">{countUsers || 0}</div>
                    <p>Actieve gebruikers {loading}</p>
                </div>
                <div className="stats-item">
                    <div className="stats-amount">{countReservations || 0}</div>
                    <p>Totaal aantal lessen {loading}</p>
                </div>
            </div>
        </div>
    )
}

export default Stats;