import React from "react";
import succes from "assets/svg/check.svg";
import error from "assets/svg/red-cross.svg";
import "./Input.css";

const DropDownInput = ({
  name,
  uniqueName,
  value,
  onChange,
  label,
  placeholder,
  options = [],
  customWidth,
  disabled,
  isValid,
  validationMessage,
  showValidation,
}) => {
  const fieldName = name || uniqueName; // Use uniqueName if name is not provided
  return (
    <div className="input" style={{ width: customWidth }}>
      {label && (
        <label className="dropdown-label" htmlFor={fieldName}>
          {label}
          {isValid ? (
          <span className={showValidation ? "error-message" : "hide-message"}>
            <img src={succes} alt="correct" />
          </span>
        ) : (
          <span className={showValidation ? "error-message" : "hide-message"}>
            <img src={error} alt="error" />
          </span>
        )}
        </label>
      )}
      <select
        id={fieldName}
        name={fieldName}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        aria-invalid={!isValid}
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {!isValid && showValidation && validationMessage && (
        <p className="error-message">{validationMessage}</p>
      )}
    </div>
  );
};

export default DropDownInput;
