import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const token = Cookies.get("token") || null;

  // Decode roles from token
  const decodeToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      return decoded.role || [];
    } catch (e) {
      console.error("Invalid token", e);
      return [];
    }
  };

  const role = token ? decodeToken(token) : [];
  const navigate = useNavigate();
  const [authState, setAuthState] = useState({ token, role });

  const setAuthInfo = (token) => {
    try {
      const decoded = jwtDecode(token);
      Cookies.set("token", token, { secure: process.env.NODE_ENV === "production", sameSite: "strict" });

      setAuthState({
        token,
        role: decoded.role || [],
      });
    } catch (e) {
      console.error("Invalid token", e);
      logout();
    }
  };

  const logout = () => {
    Cookies.remove("token");
    setAuthState({ token: null, role });
    navigate("/aanmelden");
  };

  const isAuthenticated = () => {
    if (!authState.token) return false;

    try {
      const decoded = jwtDecode(authState.token);
      return decoded.exp > Date.now() / 1000;
    } catch (e) {
      console.error("Invalid token format", e);
      return false;
    }
  };

  const isTutor = () => authState.role.includes("Teacher");
  const isStudent = () => authState.role.includes("Student");
  const isUser = () => authState.role.includes("Gebruiker");
  const isAdmin = () => authState.role.includes("Administrator");

  const makeAuthenticatedRequest = async (url, options = {}) => {
    if (!authState.token) {
      throw new Error("No authentication token available");
    }

    const headers = {
      ...options.headers,
      Authorization: `Bearer ${authState.token}`,
      "Content-Type": "application/json",
    };

    const response = await fetch(url, {
      ...options,
      headers,
    });

    if (response.status === 401) {
      logout();
      throw new Error("Unauthorized access, please log in again");
    }

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    return response.json();
  };
  

  return (
    <Provider
      value={{
        authState,
        setAuthState: setAuthInfo,
        logout,
        isAuthenticated,
        makeAuthenticatedRequest,
        isAdmin,
        isTutor,
        isStudent,
        isUser,

      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
