import { useState, useEffect, useCallback, useContext } from "react";
import { FetchContext } from "hooks/FetchContext";

const useContactMessages = () => {
  const { authAxios } = useContext(FetchContext);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchMessages = useCallback(async (startDate, endDate) => {
    setLoading(true);
    setError(null);

    try {
      const params = {};
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;

      const response = await authAxios.get("/api/ContactMessage", {
        headers: {
          Accept: "*/*",
        },
        params,
      });

      setMessages(response.data);
    } catch (err) {
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [authAxios]);

  // Fetch messages when component mounts
  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  return { messages, loading, error, fetchMessages };
};

export default useContactMessages;
