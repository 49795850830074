import PrivacyPolicy from "layouts/main/conditions/Policy";
import Footer from "layouts/main/footer/Footer";
import DesktopNav from "layouts/navigations/DesktopNav";
import MobileNav from "layouts/navigations/MobileNav";
import React from "react";

function Policy() {
  return (
    <>
      <MobileNav />
      <div className="cstm-container">
        <DesktopNav />
        <div className="title">
          <h1>Privacy Beleid van Stupu</h1>
        </div>
        <PrivacyPolicy />
        <Footer />
      </div>
    </>
  );
}

export default Policy;
