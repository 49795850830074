import React from "react";
import useChangeBackground from "utils/changeBackground";
import Footer from "layouts/dashboard/main/Footer";
import DesktopNav from "layouts/navigations/DesktopNav";
import MobileNav from "layouts/navigations/MobileNav";
import AdminCourseSettings from "layouts/dashboard/admin/setttings/Courses";
import SupportMessages from "layouts/dashboard/admin/setttings/Support";

const Settings = () => {
  useChangeBackground("/administrator-instellingen", "#59b2a5");

  return (
    <>
      <MobileNav />{" "}
      <div className="cstm-container">
        <DesktopNav />
        <div className="title">
          <h1>Admin Settings</h1>
        </div>
        <AdminCourseSettings />
        <SupportMessages /> 
        <Footer />
      </div>
    </>
  );
};

export default Settings;
