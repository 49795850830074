import React, { createContext, useContext, useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import ErrorMessage from "components/popup/ErrorMessage";
import SuccesMessage from "components/popup/SuccesMessage";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { isAuthenticated, isStudent } = useContext(AuthContext);
  const [cart, setCart] = useState(() => {
    const storedCart = sessionStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  });

  const [errorMessage, setErrorMessage] = useState(null); // Separate state for error messages
  const [succesMessage, setSuccesMessage] = useState(null); // Separate state for success messages

  const showPopup = (message, type = "success") => {
    if (type === "success") {
      setSuccesMessage(message);
      setErrorMessage(null); // Clear error message if success is shown
    } else if (type === "error") {
      setErrorMessage(message);
      setSuccesMessage(null); // Clear success message if error is shown
    }

    setTimeout(() => {
      setErrorMessage(null);
      setSuccesMessage(null);
    }, 3000);
  };

  useEffect(() => {
    if (isStudent()) {
    }
  }, [isAuthenticated, isStudent]);

  useEffect(() => {
    sessionStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);



  const addToCart = (item, selectedTimestamps) => {
    if (!Array.isArray(selectedTimestamps)) {
      console.error("selectedTimestamps should be an array");
      showPopup(
        "Er is een fout opgetreden bij het toevoegen aan de winkelwagen.",
        "error"
      );
      return;
    }
  
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (cartItem) => cartItem.teacherId === item.teacherId
      );
  
      if (existingItemIndex !== -1) {
        // Docent bestaat al in de winkelwagen, update tijdstippen
        const updatedCart = [...prevCart];
        const existingItem = updatedCart[existingItemIndex];
  
        // Controleer of de geselecteerde tijdstippen al bestaan
        const newTimestamps = selectedTimestamps.filter(
          (newTimestamp) =>
            !existingItem.selectedTimestamps.some(
              (existingTimestamp) => existingTimestamp.id === newTimestamp.id
            )
        );
  
        if (newTimestamps.length === 0) {
          showPopup("Deze tijdstippen zijn al toegevoegd aan de winkelwagen.", "error");
          return prevCart; // Geen update nodig als alle tijdstippen al bestaan
        }
  
        updatedCart[existingItemIndex] = {
          ...existingItem,
          selectedTimestamps: [...existingItem.selectedTimestamps, ...newTimestamps],
        };
  
        showPopup("Tijdstippen succesvol toegevoegd aan de winkelwagen.", "success");
        return updatedCart;
      }
  
      // Docent bestaat nog niet, voeg als nieuwe entry toe
      const updatedCart = [...prevCart, { ...item, selectedTimestamps }];
      showPopup("Item succesvol toegevoegd aan de winkelwagen.", "success");
      return updatedCart;
    });
  };
  
  
  
  

  
  

  const removeSelectedTimeCart = (itemId, timestampToRemove) => {
    setCart(
      (prevCart) =>
        prevCart
          .map((item) =>
            item.id === itemId
              ? {
                  ...item,
                  selectedTimestamps: item.selectedTimestamps.filter(
                    (t) =>
                      t.availabilityDate !==
                        timestampToRemove.availabilityDate ||
                      t.startTime !== timestampToRemove.startTime ||
                      t.endTime !== timestampToRemove.endTime
                  ),
                }
              : item
          )
          .filter((item) => item.selectedTimestamps.length > 0) // Remove item if no timestamps left
    );
  };

  const removeFromCart = (teacherId) => {
    setCart((prevCart) => prevCart.filter((item) => item.teacherId !== teacherId));
  };

  const clearCart = () => {
    setCart([]);
    sessionStorage.removeItem("cart");
    localStorage.removeItem("tutorData");

  };

  const cartCount = cart.length;

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        removeSelectedTimeCart,
        clearCart,
        cartCount,
        showPopup,
      }}
    >
      {children}
      {errorMessage && (
        <ErrorMessage
          message={errorMessage}
          onClose={() => setErrorMessage(null)}
        />
      )}
      {succesMessage && (
        <SuccesMessage
          message={succesMessage}
          onClose={() => setSuccesMessage(null)}
        />
      )}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
