import Button from "components/button/Button";
import CloseIcon from "components/button/CloseIcon";

const PopUp = ({ title, children, onCancel, bgColor, txtColor }) => {

  return (
    <div className="PopUp">
      <div className="overlay-container-2">
        <div className="popup-small">
          <div className="popup-elements box-shadow" style={{ backgroundColor: bgColor, color: txtColor }}>
            <div>
              <div className="subheading flex space-between">
                <h3>{title}</h3>
                <Button className="button-decline" onClick={onCancel}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
