import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FetchContext } from "hooks/FetchContext";
import fetchUserData from "utils/get/FetchUserData";
import DropDownInput from "components/inputs/Dropdown";

const CourseInput = () => {
  const { authAxios } = useContext(FetchContext);
  const [userData, setUserData] = useState();
  const [courses, setCourses] = useState([]); // Store filtered courses
  const [selectedCourse, setSelectedCourse] = useState(""); // Store selected value
  const [errorMessage, setErrorMessage] = useState(""); // Store error message
  const navigate = useNavigate();
  const location = useLocation(); // Get the current route
  const major = userData?.educationLevel;

  const isBijlesZoekenPage = location.pathname === "/bijles-zoeken"; // Check if user is on the page

  const getData = useCallback(async () => {
    try {
      const data = await fetchUserData(authAxios);
      setUserData(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [authAxios]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await authAxios.get("/api/Course");

        let filteredCourses = [];
        if (major) {
          filteredCourses = response.data.filter(
            (course) => course.major === major
          );
        } else if (userData?.educationType === "SecondaryEducation") {
          filteredCourses = response.data.filter(
            (course) => course.educationForm === "secundair"
          );
        } else if (userData?.educationType === "PrimaryEducation") {
          filteredCourses = response.data.filter(
            (course) => course.educationForm === "lagerOnderwijs"
          );
        } else if (userData?.academicYear && userData?.academicYear !== "All") {
          filteredCourses = response.data.filter(
            (course) => course.academicYear === userData.academicYear
          );
        } else {
          setErrorMessage(
            "Voeg uw onderwijs vorm toe om deze functie te gebruiken"
          );
          setCourses([]);
          return;
        }



        setCourses(
          filteredCourses.map((course) => ({
            value: course.id,
            label: course.name,
          }))
        );
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    if (userData) {
      fetchCourses();
    }
  }, [authAxios, userData, major]); // Runs when userData changes

  const [educationForm, setEducationForm] = useState("");

  useEffect(() => {
    if (userData?.educationType) {
      let convertedEducationForm = "";
      if (userData.educationType === "secondaryEducation") {
        convertedEducationForm = "secundair";
      } else if (userData.educationType === "primaryEducation") {
        convertedEducationForm = "lagerOnderwijs";
      } else {
        convertedEducationForm = userData.educationType;
      }

      setEducationForm(convertedEducationForm);
    }
  }, [userData]); // Runs when userData changes



  const handleCourseChange = (e) => {
    const courseId = e.target.value;

    const selectedCourseData = courses.find(
      (course) => course.value === courseId
    );

    if (selectedCourseData) {

      setSelectedCourse(courseId);

      console.log("Navigating with:", {
        courseId,
        educationForm, // Now using state instead of direct userData
        major,
      });

      navigate(
        `/bijles-zoeken?course=${courseId}&educationForm=${educationForm}&major=${major}`
      );
    }
  };

  return (
    <div>
      {errorMessage ? (
        <p style={{ color: "red", fontSize: "14px" }}>{errorMessage}</p>
      ) : (
        <DropDownInput
          placeholder={"Bijles zoeken op vak..."}
          name="course"
          value={selectedCourse}
          onChange={handleCourseChange}
          options={courses}
          disabled={isBijlesZoekenPage || courses.length === 0} // Disable dropdown on /bijles-zoeken
        />        
        
      )}
    </div>
  );
};

export default CourseInput;
