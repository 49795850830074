import Button from "components/button/Button";
import PopUp from "components/popup/PopUp";
import React, { useState, useEffect } from "react";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <PopUp title={"Essentiële & Functionele cookies"} bgColor={"white"} txtColor={"#468275"} >
      <div className="fixed bottom-0 left-0 right-0 bg-gray-900 text-white p-4 flex justify-between items-center">
        <p className="text-sm ptb-1">
          Wij gebruiken alleen cookies die noodzakelijk zijn voor het goed
          functioneren van ons platform. Deze cookies zorgen ervoor dat de
          website correct werkt en kunnen niet worden uitgeschakeld. Meer
          informatie vindt u in ons 
          <a className={"primary-color m-05"} href="/privacy-beleid" >
            Privacybeleid
          </a>
          .
        </p>
      </div>
      <div className="flex justify-content-end mt-1">
        <Button className="button-choiceSec" onClick={handleAccept}>
          Begrijp ik
        </Button>
      </div>
    </PopUp>
  );
};

export default CookieConsent;
