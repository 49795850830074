import { useContext, useState } from "react";
import { FetchContext } from "hooks/FetchContext";

const useMarkAsRead = () => {
   const {authAxios } = useContext(FetchContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const markAsRead = async (messageId) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await authAxios.patch(
        `/api/ContactMessage/${messageId}`,
        { isRead: true },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      );

      setSuccess(true);
    } catch (err) {
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return { markAsRead, loading, error, success };
};

export default useMarkAsRead;
